.btn-icon {
    border: none;
    background: transparent;
    padding: 0 !important;
    line-height: 1.06;
    padding-left: 0.5px !important;
}

.btn-default {
    .btn-icon-color-variant(@brand-default);
}

.btn-primary {
    .btn-icon-color-variant(@brand-primary);
}

.btn-secondary {
    .btn-icon-color-variant(@brand-secondary);
}

.btn-icon-info {
    .btn-icon-content-variant("\e906", "\e916");
}

.btn-icon-link {
    .btn-icon-content-variant("\e907", "\e917");
}

.btn-icon-pointer {
    .btn-icon-content-variant("\e909", "\e908");
}

.btn-icon-badge {
    .btn-icon-content-variant("\e90e", "\e90c");
    position: relative;
    span {
        position: absolute;
        font-weight: bold;
        left: 0;
        right: 0;
        .scale-prop(font-size, 18px, 22px, @screen-mobile, @screen-desktop);
        top: calc(50% - 0.45em);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    .btn-icon {
        vertical-align: text-bottom;
        &,
        & .icon {
            font-size: 1em;
        }
    }
}

.btn-icon-color-variant(@color) {
    &.btn-icon {
        border-color: transparent;
        background-color: transparent;
        &::before,
        .icon {
            color: @color;
        }
    }
}

.btn-icon-content-variant(@idle-content, @focus-content) {
    &::before {
        font-family: @icon-font;
        content: @idle-content;
        .scale-prop(font-size, 30px, 40px, @screen-mobile, @screen-desktop);
    }
    &.open,
    &:active,
    &:hover,
    &:focus,
    &:active:hover,
    &active:focus {
        &::before {
            content: @focus-content;
        }
    }
}