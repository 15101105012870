.list-group.reference-links {
  background-color: #F1F1EE;
  margin: 0;
  border-radius: @border-radius-default;
  .list-group-item {
      border: none;
      background-color: transparent;
      position: relative;
      padding-right: 2rem;
      .icon {
          position: absolute;
          top: 10px;
          right: 0.5rem;
      }
      &:not(:last-child){
          border-bottom: 1px solid @brand-secondary;
      }
  }
}