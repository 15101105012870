.navbar.app-bar {
  display: table;
  width: 100%;
  .container-fluid {
      display: table-row;
  }
  .navbar {
      &-header,
      &-nav,
      &-brand {
          display: table-cell;
          float: none;
          vertical-align: middle;
      }
      &-header {
          width: 100%;
      }
      &-nav {
          white-space: nowrap;
      }
      &-brand {
          img {
              width: 1.5rem;
          }
      }
  }
}

@media (max-width: @screen-md) {
  .navbar.app-bar {
      &,
      & .container-fluid {
          padding-left: 0;
          padding-right: 0;
      }
  }
}

.navbar.app-bar {
  .navbar-header {
      .page-header {
          display: table-cell;
          float: none;
          vertical-align: middle;
          margin: 0;
          padding: 0;
          [class*="text-"] {
              margin: 0;
          }
      }
  }
}