.footer-main {
    padding: 4px 40px 4px 25px;
    background-color: @brand-default;
    .footer-info {
        margin: 0;
        .scale-prop(padding-left, 30px, 35px, @screen-mobile, @screen-desktop);
        .scale-prop(padding-right, 30px, 35px, @screen-mobile, @screen-desktop);
        .scale-prop(padding-top, 11px, 16px, @screen-mobile, @screen-desktop);
        .scale-prop(padding-bottom, 11px, 16px, @screen-mobile, @screen-desktop);
        color: #fff;
        font-size: 15px;    
    }
    .list-horizontal {
        margin: 0;
        float: right;
        li {
            display: inline-block;
            .btn-link {
                color: #fff;                
                font-size: 15px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

@media screen and (max-width: @screen-md) {
    .footer-main {
        padding: 4px 1px 30px 1px;
        .footer-info {
            padding: 18px 0 8px 0;
        }
        .list-horizontal {
            padding: 0;
            float: left;
            li {
                display: block;
                .btn-link {
                    padding: 0;
                }
            }
        }
    }
}