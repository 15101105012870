.interactive-image {
    border: 1px solid @brand-primary;
    .scale-prop(padding, 20px, 30px, @screen-mobile, @screen-desktop);
    border-radius: @border-radius-default;
    position: relative;
    &>&-tooltip {
        display: inline-block;
        position: absolute;
        .scale-prop(top, 10px, 15px, @screen-mobile, @screen-desktop);
        .scale-prop(left, 10px, 15px, @screen-mobile, @screen-desktop);
        z-index: 1;
    }
    & svg &-tooltip {
        stroke: @brand-primary;
        stroke-width: 2px;
        fill: fade(@brand-primary, 15%);
        &:hover,
        &.open {
          fill: fade(@brand-primary, 50%);
        }
    }
}