.list-group.notifications {
  display: inline-block;
  margin-bottom: 0;
  .list-group-item-variant(@color) {
      color: @color;
      .badge {
          color: #fff;
          background-color: @color;
          border-color: @color;
      }
      &:hover,
      &:focus {
          color: @color;
          .badge {
              color: @color;
              background-color: #fff;
          }
      }
  }
  .list-group-item {
      border: none;
      color: @brand-primary;
      padding: 0;
      .scale-prop(margin-bottom, 15px, 10px, @screen-mobile, @screen-desktop);
      .scale-prop(font-size, 18px, 20px, @screen-mobile, @screen-desktop);
      .scale-prop(line-height, 35px, 45px, @screen-mobile, @screen-desktop);
      &:hover,
      &:focus {
          background-color: transparent;
      }
      &:first-child,
      &:last-child {
          border-radius: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .badge {
          display: block;
          margin-right: 0.75rem;
          padding: 0;
          float: left;
          border: 2px solid;
          border-radius: 50%;
          .scale-prop(width, 35px, 45px, @screen-mobile, @screen-desktop);
          .scale-prop(height, 35px, 45px, @screen-mobile, @screen-desktop);
          .scale-prop(font-size, 18px, 22px, @screen-mobile, @screen-desktop);
          .scale-prop(line-height, 30px, 40px, @screen-mobile, @screen-desktop);
      }
      &-primary {
          .list-group-item-variant(@brand-primary);
      }
      &-default {
          .list-group-item-variant(@brand-default);
      }
      &-secondary {
          .list-group-item-variant(@brand-secondary);
      }
  }
  @media screen and (min-width: @screen-md) {
      .list-group-item {
          text-align: right;
          .badge {
              float: right;
              margin-left: 1rem;
          }
      }
  }
}