.dashboard {
    .scale-prop(padding-top, 85px, 100px, @screen-mobile, @screen-desktop);
}

.container.dashboard-header-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
    .dashboard-header {
      padding-bottom: 1rem;
    }
}

@media (min-width: @screen-md) {
    .container.dashboard-header-section {
        display: table;
        .row {
            display: table-row;
        }
        [class*='col-'] {
            display: table-cell;
            float: none;
            padding-left: 0;
            padding-right: 0;
            vertical-align: middle;
        }
        .dashboard-header {
            padding: 1rem 0;
        }
        .dashboard-notifications {
            text-align: right;
        }
    }
}