.questionnaire-bottom-nav{
    & > div:first-child{
        & > img {
            width: 60px;
            height: 60px; 
        }
    }
    .progress-bar-container {
        @media (max-width: @screen-sm-max) {
            margin-bottom: 25px;
        }
        &>.progress {
            height: 10px;
            top: calc(0.5em - 1px);
        }
    }
    .pager {
        margin: 0;
        white-space: nowrap;
        text-align: right;
        @media screen and(min-width: @screen-md-min) {
            float: right;
            &>* {
                display: inline-block;
                &:last-child {
                    margin-left: 20px;
                }
            }
        }
        @media screen and(max-width: @screen-sm-max) {
            &>* {
                width: 100%;
                &>a {
                    width: 45%;
                }
            }
        }
    }
}