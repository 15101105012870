.dashboard {
  padding-top: 4.25rem;
}
@media screen and (min-width: 375px) {
  .dashboard {
    padding-top: calc(4.25rem + 15 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .dashboard {
    padding-top: 5rem;
  }
}
.container.dashboard-header-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.container.dashboard-header-section .dashboard-header {
  padding-bottom: 1rem;
}
@media (min-width: 992px) {
  .container.dashboard-header-section {
    display: table;
  }
  .container.dashboard-header-section .row {
    display: table-row;
  }
  .container.dashboard-header-section [class*='col-'] {
    display: table-cell;
    float: none;
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }
  .container.dashboard-header-section .dashboard-header {
    padding: 1rem 0;
  }
  .container.dashboard-header-section .dashboard-notifications {
    text-align: right;
  }
}
.list-group.notifications {
  display: inline-block;
  margin-bottom: 0;
}
.list-group.notifications .list-group-item {
  border: none;
  color: #6D54FF;
  padding: 0;
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
  line-height: 1.75rem;
}
@media screen and (min-width: 375px) {
  .list-group.notifications .list-group-item {
    margin-bottom: calc(0.75rem + -5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .list-group.notifications .list-group-item {
    margin-bottom: 0.5rem;
  }
}
@media screen and (min-width: 375px) {
  .list-group.notifications .list-group-item {
    font-size: calc(0.9rem + 2 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .list-group.notifications .list-group-item {
    font-size: 1rem;
  }
}
@media screen and (min-width: 375px) {
  .list-group.notifications .list-group-item {
    line-height: calc(1.75rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .list-group.notifications .list-group-item {
    line-height: 2.25rem;
  }
}
.list-group.notifications .list-group-item:hover,
.list-group.notifications .list-group-item:focus {
  background-color: transparent;
}
.list-group.notifications .list-group-item:first-child,
.list-group.notifications .list-group-item:last-child {
  border-radius: 0;
}
.list-group.notifications .list-group-item:last-child {
  margin-bottom: 0;
}
.list-group.notifications .list-group-item .badge {
  display: block;
  margin-right: 0.75rem;
  padding: 0;
  float: left;
  border: 2px solid;
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.9rem;
  line-height: 1.5rem;
}
@media screen and (min-width: 375px) {
  .list-group.notifications .list-group-item .badge {
    width: calc(1.75rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .list-group.notifications .list-group-item .badge {
    width: 2.25rem;
  }
}
@media screen and (min-width: 375px) {
  .list-group.notifications .list-group-item .badge {
    height: calc(1.75rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .list-group.notifications .list-group-item .badge {
    height: 2.25rem;
  }
}
@media screen and (min-width: 375px) {
  .list-group.notifications .list-group-item .badge {
    font-size: calc(0.9rem + 4 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .list-group.notifications .list-group-item .badge {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 375px) {
  .list-group.notifications .list-group-item .badge {
    line-height: calc(1.5rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .list-group.notifications .list-group-item .badge {
    line-height: 2rem;
  }
}
.list-group.notifications .list-group-item-primary {
  color: #6D54FF;
}
.list-group.notifications .list-group-item-primary .badge {
  color: #fff;
  background-color: #6D54FF;
  border-color: #6D54FF;
}
.list-group.notifications .list-group-item-primary:hover,
.list-group.notifications .list-group-item-primary:focus {
  color: #6D54FF;
}
.list-group.notifications .list-group-item-primary:hover .badge,
.list-group.notifications .list-group-item-primary:focus .badge {
  color: #6D54FF;
  background-color: #fff;
}
.list-group.notifications .list-group-item-default {
  color: #3F3D35;
}
.list-group.notifications .list-group-item-default .badge {
  color: #fff;
  background-color: #3F3D35;
  border-color: #3F3D35;
}
.list-group.notifications .list-group-item-default:hover,
.list-group.notifications .list-group-item-default:focus {
  color: #3F3D35;
}
.list-group.notifications .list-group-item-default:hover .badge,
.list-group.notifications .list-group-item-default:focus .badge {
  color: #3F3D35;
  background-color: #fff;
}
.list-group.notifications .list-group-item-secondary {
  color: #B0B39D;
}
.list-group.notifications .list-group-item-secondary .badge {
  color: #fff;
  background-color: #B0B39D;
  border-color: #B0B39D;
}
.list-group.notifications .list-group-item-secondary:hover,
.list-group.notifications .list-group-item-secondary:focus {
  color: #B0B39D;
}
.list-group.notifications .list-group-item-secondary:hover .badge,
.list-group.notifications .list-group-item-secondary:focus .badge {
  color: #B0B39D;
  background-color: #fff;
}
@media screen and (min-width: 992px) {
  .list-group.notifications .list-group-item {
    text-align: right;
  }
  .list-group.notifications .list-group-item .badge {
    float: right;
    margin-left: 1rem;
  }
}
.questionnaire-info {
  display: table;
  margin-top: 0.9rem;
}
@media screen and (min-width: 375px) {
  .questionnaire-info {
    margin-top: calc(0.9rem + 12 * ((100vw - 375px) / 617));
  }
}
@media screen and (min-width: 992px) {
  .questionnaire-info {
    margin-top: 1.5rem;
  }
}
.questionnaire-info-progress {
  display: table-cell;
  vertical-align: top;
  font-size: 0.65rem;
}
@media screen and (min-width: 375px) {
  .questionnaire-info-progress {
    font-size: calc(0.65rem + 3 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .questionnaire-info-progress {
    font-size: 0.8rem;
  }
}
.questionnaire-info-progress .icon {
  line-height: calc(1rem * 1.42857143);
}
@media (max-width: 991px) {
  .questionnaire-info-progress .icon-progress {
    top: calc(0.5rem * 1.42857143/2);
  }
}
.questionnaire-info-body {
  display: table-cell;
  width: 100%;
  line-height: calc(1rem * 1.42857143);
  padding-left: 15px;
}
.questionnaire-info-body > * {
  margin-bottom: 1rem;
}
.questionnaire-info-title {
  font-size: 1rem;
}
@media screen and (min-width: 375px) {
  .questionnaire-info-title {
    font-size: calc(1rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .questionnaire-info-title {
    font-size: 1.5rem;
  }
}
.questionnaire-info-todo {
  color: #6D54FF;
}
.questionnaire-info-incomplete {
  color: #3F3D35;
}
.questionnaire-info-complete {
  color: #B0B39D;
}
@media screen and (min-width: 992px) {
  .questionnaire-info {
    padding: 15px 0;
    margin-top: 0;
  }
  .questionnaire-info-progress {
    vertical-align: middle;
  }
  .questionnaire-info-body > * {
    display: table-cell;
    vertical-align: middle;
  }
  .questionnaire-info-title {
    width: 100vw;
  }
  .questionnaire-info-actions {
    white-space: nowrap;
    direction: rtl;
  }
}
.questionnaire-bottom-nav > div:first-child > img {
  width: 60px;
  height: 60px;
}
@media (max-width: 991px) {
  .questionnaire-bottom-nav .progress-bar-container {
    margin-bottom: 25px;
  }
}
.questionnaire-bottom-nav .progress-bar-container > .progress {
  height: 10px;
  top: calc(0.5em - 1px);
}
.questionnaire-bottom-nav .pager {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}
@media screen and (min-width: 992px) {
  .questionnaire-bottom-nav .pager {
    float: right;
  }
  .questionnaire-bottom-nav .pager > * {
    display: inline-block;
  }
  .questionnaire-bottom-nav .pager > *:last-child {
    margin-left: 20px;
  }
}
@media screen and (max-width: 991px) {
  .questionnaire-bottom-nav .pager > * {
    width: 100%;
  }
  .questionnaire-bottom-nav .pager > * > a {
    width: 45%;
  }
}
.questionnaire {
  padding-top: 4.25rem;
  padding-bottom: 30rem;
}
@media screen and (min-width: 375px) {
  .questionnaire {
    padding-top: calc(4.25rem + 15 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .questionnaire {
    padding-top: 5rem;
  }
}
.panel-searchable .list-group {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.panel-searchbox {
  padding: 15px;
  border: 1px solid;
}
.panel-searchbox .form-group {
  margin-bottom: 0;
}
.panel-searchbox .form-control:first-child {
  height: 100%;
  border-left: none;
  border-bottom-left-radius: 0!important;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 0!important;
  direction: ltr;
}
.panel-searchbox .input-group-addon {
  width: 1px;
  height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.25rem;
  font-size: 1.2rem;
  border: 2px solid #B0B39D;
  border-right: none;
  border-radius: 10px 0 0 10px;
  transition: border-color ease-in-out 0.15s;
  cursor: pointer;
}
@media screen and (min-width: 375px) {
  .panel-searchbox .input-group-addon {
    padding-left: calc(0.5rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .panel-searchbox .input-group-addon {
    padding-left: 1rem;
  }
}
@media screen and (min-width: 375px) {
  .panel-searchbox .input-group-addon {
    padding-right: calc(0.25rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .panel-searchbox .input-group-addon {
    padding-right: 0.5rem;
  }
}
.panel-searchbox .input-group-addon::before {
  color: #B0B39D;
  content: '\e913';
}
.panel-searchbox .input-group {
  height: 2.5rem;
  direction: rtl;
}
@media screen and (min-width: 375px) {
  .panel-searchbox .input-group {
    height: calc(2.5rem + 15 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .panel-searchbox .input-group {
    height: 3.25rem;
  }
}
.panel-searchbox .form-control:hover ~ .input-group-addon,
.panel-searchbox .form-control:focus ~ .input-group-addon {
  border-color: #6D54FF;
}
.panel-searchbox + .panel-body {
  border-top-width: 0;
}
@media screen and (min-width: 992px) {
  .panel-searchable .panel {
    border: none;
  }
  .panel-searchable .panel-heading {
    float: left;
  }
  .panel-searchable .panel-searchbox {
    float: right;
    border-color: transparent;
    padding: 0;
  }
  .panel-searchable .panel-body {
    clear: both;
  }
  .panel-searchable .panel-searchbox + .panel-searchable .panel-body,
  .panel-searchable .panel * + .list-group {
    border-top-width: 1px;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
  .panel-searchable .list-group {
    clear: both;
  }
  .panel-searchable * + .list-group {
    border-top-width: 1px;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
  .panel-searchable .list-group .list-group-item:not(:first-child) {
    border-top: 2px solid #F1F1EE !important;
  }
  .panel-searchable .list-group .list-group-item:last-child {
    border-bottom: 2px solid #F1F1EE !important;
  }
}
.navbar.app-bar {
  display: table;
  width: 100%;
}
.navbar.app-bar .container-fluid {
  display: table-row;
}
.navbar.app-bar .navbar-header,
.navbar.app-bar .navbar-nav,
.navbar.app-bar .navbar-brand {
  display: table-cell;
  float: none;
  vertical-align: middle;
}
.navbar.app-bar .navbar-header {
  width: 100%;
}
.navbar.app-bar .navbar-nav {
  white-space: nowrap;
}
.navbar.app-bar .navbar-brand img {
  width: 1.5rem;
}
@media (max-width: 992px) {
  .navbar.app-bar,
  .navbar.app-bar .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
.navbar.app-bar .navbar-header .page-header {
  display: table-cell;
  float: none;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
.navbar.app-bar .navbar-header .page-header [class*="text-"] {
  margin: 0;
}
.login-page .logo {
  display: none;
  width: 31px;
  float: left;
  margin: 30px 0 0 30px;
}
.login-page .logo-subtitle {
  display: block;
  margin: 0 15px 0 0;
}
.login-page .login-background {
  display: none;
  overflow: hidden;
  height: 100vh;
  width: 50%;
  position: fixed;
  text-align: right;
  top: 0;
  right: 0;
}
.login-page .login-background img {
  height: 100%;
  min-height: 722px;
}
@media (min-width: 992px) {
  .login-page .container .row {
    margin-top: calc((100vh - 700px) / 2);
  }
  .login-page .logo {
    display: block;
  }
  .login-page .login-background {
    display: block;
  }
  .login-page .logo-subtitle {
    display: none;
  }
}
.interactive-image {
  border: 1px solid #6D54FF;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
}
@media screen and (min-width: 375px) {
  .interactive-image {
    padding: calc(1rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .interactive-image {
    padding: 1.5rem;
  }
}
.interactive-image > .interactive-image-tooltip {
  display: inline-block;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 1;
}
@media screen and (min-width: 375px) {
  .interactive-image > .interactive-image-tooltip {
    top: calc(0.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .interactive-image > .interactive-image-tooltip {
    top: 0.75rem;
  }
}
@media screen and (min-width: 375px) {
  .interactive-image > .interactive-image-tooltip {
    left: calc(0.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .interactive-image > .interactive-image-tooltip {
    left: 0.75rem;
  }
}
.interactive-image svg .interactive-image-tooltip {
  stroke: #6D54FF;
  stroke-width: 2px;
  fill: rgba(109, 84, 255, 0.15);
}
.interactive-image svg .interactive-image-tooltip:hover,
.interactive-image svg .interactive-image-tooltip.open {
  fill: rgba(109, 84, 255, 0.5);
}
.table-list .table-only {
  display: none;
}
.table-list .table-list-header {
  display: none;
}
.table-list .panel-heading .btn,
.table-list .panel-footer .btn {
  color: #B0B39D;
  background-color: transparent;
}
.table-list .panel-heading .btn:hover,
.table-list .panel-footer .btn:hover,
.table-list .panel-heading .btn:active,
.table-list .panel-footer .btn:active,
.table-list .panel-heading .btn:focus,
.table-list .panel-footer .btn:focus,
.table-list .panel-heading .btn:active:hover,
.table-list .panel-footer .btn:active:hover,
.table-list .panel-heading .btn:active:focus,
.table-list .panel-footer .btn:active:focus {
  color: #6D54FF;
}
.table-list .panel-heading .btn .icon,
.table-list .panel-footer .btn .icon {
  font-size: 1.5rem;
}
@media screen and (min-width: 375px) {
  .table-list .panel-heading .btn .icon,
  .table-list .panel-footer .btn .icon {
    font-size: calc(1.5rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .table-list .panel-heading .btn .icon,
  .table-list .panel-footer .btn .icon {
    font-size: 2rem;
  }
}
.table-list .panel-heading .btn span:not(.icon),
.table-list .panel-footer .btn span:not(.icon) {
  line-height: 1.5rem;
  margin-right: 0.75rem;
}
@media screen and (min-width: 375px) {
  .table-list .panel-heading .btn span:not(.icon),
  .table-list .panel-footer .btn span:not(.icon) {
    margin-right: calc(0.75rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .table-list .panel-heading .btn span:not(.icon),
  .table-list .panel-footer .btn span:not(.icon) {
    margin-right: 1.25rem;
  }
}
.table-list .panel-heading .btn:last-child,
.table-list .panel-footer .btn:last-child {
  margin-right: -1.5rem;
}
@media screen and (min-width: 375px) {
  .table-list .panel-heading .btn:last-child,
  .table-list .panel-footer .btn:last-child {
    margin-right: calc(-1.5rem + -5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .table-list .panel-heading .btn:last-child,
  .table-list .panel-footer .btn:last-child {
    margin-right: -1.75rem;
  }
}
.table-list .panel-footer {
  text-align: right;
  background-color: #fff;
  border-top-color: #fff;
  margin-top: -2px;
}
@media (max-width: 991px) {
  .table-list .panel-body {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .table-list .panel-body.collapse.in {
    overflow: visible;
  }
  .table-list .panel-body > *:first-child {
    margin-top: 15px;
  }
  .table-list .panel-body > *:last-child {
    margin-bottom: 15px;
  }
  .table-list .panel-heading .caret {
    border: none;
    transform: rotate(180deg);
    width: auto;
    height: auto;
    margin: 0;
    float: right;
  }
  .table-list .panel-heading .caret::before {
    font-family: 'MediPrepare-Icons';
    content: '\e900';
  }
  .table-list .panel-heading.collapsed .caret {
    transform: rotate(0deg);
  }
  .table-list .panel-heading:not(.collapsed) .panel-body.collapsing + .panel-footer > * {
    display: block;
  }
  .table-list .panel-heading.collapsed + .panel-body.collapse:not(.in) + .panel-footer > *,
  .table-list .panel-heading.collapsed + .panel-body.collapsing + .panel-footer > * {
    display: none;
  }
}
@media (min-width: 992px) {
  .list-only {
    display: none;
  }
  .table-only {
    display: block;
  }
  .table-list {
    display: table;
  }
  .table-list .table-list-header {
    display: table-row;
  }
  .table-list .table-list-header > * {
    padding-bottom: 0;
  }
  .table-list .table-list-header [class*="col-"] {
    font-size: 1.25rem;
  }
  .table-list .panel {
    display: table-row;
  }
  .table-list .panel-heading,
  .table-list .panel-body,
  .table-list .panel-footer {
    display: table-cell;
    border: none;
    background-color: #fff;
    color: #3F3D35;
    vertical-align: middle;
    overflow: visible;
  }
  .table-list .panel-heading,
  .table-list .panel-footer {
    padding: 0;
  }
  .table-list .panel-heading .btn,
  .table-list .panel-footer .btn {
    padding: 0;
  }
  .table-list .panel-body [class*='col-'] {
    display: inline-block;
  }
  .table-list .panel-body [class*='col-']:not(:last-child) {
    padding-right: 30px;
  }
  .table-list .panel-body .col-1 {
    width: 12.5%;
  }
  .table-list .panel-body .col-2 {
    width: 25%;
  }
  .table-list .panel-body .col-3 {
    width: 37.5%;
  }
  .table-list .panel-body .col-4 {
    width: 50%;
  }
  .table-list .panel-body .col-5 {
    width: 62.5%;
  }
  .table-list .panel-body .col-6 {
    width: 75%;
  }
  .table-list .panel-body .col-7 {
    width: 87.5%;
  }
  .table-list .panel-body .col-8 {
    width: 100%;
  }
  .table-list .panel-body .form-group {
    margin-bottom: 0;
  }
  .table-list .panel-body .form-group label {
    display: none;
  }
}
@media (min-width: 992px) and screen and (min-width: 375px) {
  .table-list .table-list-header [class*="col-"] {
    font-size: calc(1.25rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media (min-width: 992px) and screen and (min-width: 1920px) {
  .table-list .table-list-header [class*="col-"] {
    font-size: 1.5rem;
  }
}
.footer-main {
  padding: 4px 40px 4px 25px;
  background-color: #3F3D35;
}
.footer-main .footer-info {
  margin: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  color: #fff;
  font-size: 15px;
}
@media screen and (min-width: 375px) {
  .footer-main .footer-info {
    padding-left: calc(1.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .footer-main .footer-info {
    padding-left: 1.75rem;
  }
}
@media screen and (min-width: 375px) {
  .footer-main .footer-info {
    padding-right: calc(1.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .footer-main .footer-info {
    padding-right: 1.75rem;
  }
}
@media screen and (min-width: 375px) {
  .footer-main .footer-info {
    padding-top: calc(0.55rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .footer-main .footer-info {
    padding-top: 0.8rem;
  }
}
@media screen and (min-width: 375px) {
  .footer-main .footer-info {
    padding-bottom: calc(0.55rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .footer-main .footer-info {
    padding-bottom: 0.8rem;
  }
}
.footer-main .list-horizontal {
  margin: 0;
  float: right;
}
.footer-main .list-horizontal li {
  display: inline-block;
}
.footer-main .list-horizontal li .btn-link {
  color: #fff;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 992px) {
  .footer-main {
    padding: 4px 1px 30px 1px;
  }
  .footer-main .footer-info {
    padding: 18px 0 8px 0;
  }
  .footer-main .list-horizontal {
    padding: 0;
    float: left;
  }
  .footer-main .list-horizontal li {
    display: block;
  }
  .footer-main .list-horizontal li .btn-link {
    padding: 0;
  }
}
.list-group.reference-links {
  background-color: #F1F1EE;
  margin: 0;
  border-radius: 10px;
}
.list-group.reference-links .list-group-item {
  border: none;
  background-color: transparent;
  position: relative;
  padding-right: 2rem;
}
.list-group.reference-links .list-group-item .icon {
  position: absolute;
  top: 10px;
  right: 0.5rem;
}
.list-group.reference-links .list-group-item:not(:last-child) {
  border-bottom: 1px solid #B0B39D;
}
.btn-icon {
  border: none;
  background: transparent;
  padding: 0 !important;
  line-height: 1.06;
  padding-left: 0.5px !important;
}
.btn-default.btn-icon {
  border-color: transparent;
  background-color: transparent;
}
.btn-default.btn-icon::before,
.btn-default.btn-icon .icon {
  color: #3F3D35;
}
.btn-primary.btn-icon {
  border-color: transparent;
  background-color: transparent;
}
.btn-primary.btn-icon::before,
.btn-primary.btn-icon .icon {
  color: #6D54FF;
}
.btn-secondary.btn-icon {
  border-color: transparent;
  background-color: transparent;
}
.btn-secondary.btn-icon::before,
.btn-secondary.btn-icon .icon {
  color: #B0B39D;
}
.btn-icon-info::before {
  font-family: 'MediPrepare-Icons';
  content: "\e906";
  font-size: 1.5rem;
}
@media screen and (min-width: 375px) {
  .btn-icon-info::before {
    font-size: calc(1.5rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn-icon-info::before {
    font-size: 2rem;
  }
}
.btn-icon-info.open::before,
.btn-icon-info:active::before,
.btn-icon-info:hover::before,
.btn-icon-info:focus::before,
.btn-icon-info:active:hover::before,
.btn-icon-infoactive:focus::before {
  content: "\e916";
}
.btn-icon-link::before {
  font-family: 'MediPrepare-Icons';
  content: "\e907";
  font-size: 1.5rem;
}
@media screen and (min-width: 375px) {
  .btn-icon-link::before {
    font-size: calc(1.5rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn-icon-link::before {
    font-size: 2rem;
  }
}
.btn-icon-link.open::before,
.btn-icon-link:active::before,
.btn-icon-link:hover::before,
.btn-icon-link:focus::before,
.btn-icon-link:active:hover::before,
.btn-icon-linkactive:focus::before {
  content: "\e917";
}
.btn-icon-pointer::before {
  font-family: 'MediPrepare-Icons';
  content: "\e909";
  font-size: 1.5rem;
}
@media screen and (min-width: 375px) {
  .btn-icon-pointer::before {
    font-size: calc(1.5rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn-icon-pointer::before {
    font-size: 2rem;
  }
}
.btn-icon-pointer.open::before,
.btn-icon-pointer:active::before,
.btn-icon-pointer:hover::before,
.btn-icon-pointer:focus::before,
.btn-icon-pointer:active:hover::before,
.btn-icon-pointeractive:focus::before {
  content: "\e908";
}
.btn-icon-badge {
  position: relative;
}
.btn-icon-badge::before {
  font-family: 'MediPrepare-Icons';
  content: "\e90e";
  font-size: 1.5rem;
}
@media screen and (min-width: 375px) {
  .btn-icon-badge::before {
    font-size: calc(1.5rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn-icon-badge::before {
    font-size: 2rem;
  }
}
.btn-icon-badge.open::before,
.btn-icon-badge:active::before,
.btn-icon-badge:hover::before,
.btn-icon-badge:focus::before,
.btn-icon-badge:active:hover::before,
.btn-icon-badgeactive:focus::before {
  content: "\e90c";
}
.btn-icon-badge span {
  position: absolute;
  font-weight: bold;
  left: 0;
  right: 0;
  font-size: 0.9rem;
  top: calc(50% - 0.45em);
}
@media screen and (min-width: 375px) {
  .btn-icon-badge span {
    font-size: calc(0.9rem + 4 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn-icon-badge span {
    font-size: 1.1rem;
  }
}
h1 .btn-icon,
h2 .btn-icon,
h3 .btn-icon,
h4 .btn-icon,
h5 .btn-icon,
h6 .btn-icon {
  vertical-align: text-bottom;
}
h1 .btn-icon,
h2 .btn-icon,
h3 .btn-icon,
h4 .btn-icon,
h5 .btn-icon,
h6 .btn-icon,
h1 .btn-icon .icon,
h2 .btn-icon .icon,
h3 .btn-icon .icon,
h4 .btn-icon .icon,
h5 .btn-icon .icon,
h6 .btn-icon .icon {
  font-size: 1em;
}
/*# sourceMappingURL=mediprepare-theme-components.css.map */