.questionnaire-info {
  display: table;
  .scale-prop(margin-top, 18px, 30px, @screen-mobile, @screen-md);
  &-progress {
      display: table-cell;
      vertical-align: top;
      .scale-prop(font-size, 13px, 16px, @screen-mobile, @screen-desktop);
      .icon {
          line-height: calc(1rem * @line-height-base);
      }
      @media (max-width: @screen-sm-max) {
          .icon-progress{
              top:calc(0.5rem * @line-height-base/2);
          }
      }
      
  }
  &-body {
      display: table-cell;
      width: 100%;
      line-height: calc(1rem * @line-height-base);
      padding-left: @panel-body-padding;
      &>* {
          margin-bottom: 1rem;
      }
  }
  &-title {
      .scale-prop(font-size, 20px, 30px, @screen-mobile, @screen-desktop);
  }
  &-todo {
      color: @brand-primary;
  }
  &-incomplete {
      color: @brand-default;
  }
  &-complete {
      color: @brand-secondary;
  }
}

@media screen and (min-width: @screen-md) {
  .questionnaire-info {
      padding: 15px 0;
      margin-top: 0;
      &-progress {
          vertical-align: middle;
      }
      &-body {
          &>* {
              display: table-cell;
              vertical-align: middle;
          }
      }
      &-title {
          width: 100vw;
      }
      &-actions {
          white-space: nowrap;
          direction: rtl;
      }
  }
}