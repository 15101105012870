.login-page {
    .logo {
        display: none;
        width: 31px;
        float: left;
        margin: 30px 0 0 30px;
    }
    .logo-subtitle {
        display: block;
        margin: 0 15px 0 0;
    }
    .login-background {
        display: none;
        overflow: hidden;
        height: 100vh;
        width: 50%;
        position: fixed;
        text-align: right;
        top: 0;
        right: 0;
        img {
            height: 100%;
            min-height: 722px;
        }
    }

    @media (min-width: @screen-md) {
        .container .row {
            margin-top: calc((100vh - 700px) / 2);
        }
        .logo {
            display: block;
        }
        .login-background {
            display: block;
        }
        .logo-subtitle {
            display: none;
        }
    }
}

