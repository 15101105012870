.panel {
    &-searchable {
        .list-group {
            .border-bottom-radius(@border-radius-default);
        }
    }
    &-searchbox {
        padding: @panel-body-padding;
        border: 1px solid;
        .form-group {
            margin-bottom: 0;        
        }
        .form-control:first-child {
            height: 100%;
            border-left: none;
            border-bottom-left-radius: 0!important;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            border-top-left-radius: 0!important;        
            direction: ltr;
        }
        .input-group-addon {
            width: 1px;
            height: 100%;
            .scale-prop(padding-left, 10px, 20px, @screen-mobile, @screen-desktop);
            .scale-prop(padding-right, 5px, 10px, @screen-mobile, @screen-desktop);
            font-size: 1.2rem;
            border: 2px solid @brand-secondary;
            border-right: none;
            border-radius: 10px 0 0 10px;     
            transition: border-color ease-in-out .15s;
            cursor: pointer;
            &::before {
                color: @brand-secondary;
                content: '\e913';
            }
        }
        .input-group {
            .scale-prop(height, 50px, 65px, @screen-mobile, @screen-desktop);
            direction: rtl;
        }
        .form-control:hover ~ .input-group-addon,
        .form-control:focus ~ .input-group-addon {
            border-color: @brand-primary;
        }
    }
    &-searchbox+&-body {
        border-top-width: 0;
    }
}

@media screen and (min-width: @screen-md) {
    .panel-searchable {
        .panel {
            border: none;
            &-heading {
                float: left;
            }
            &-searchbox {
                float: right;
                border-color: transparent;
                padding: 0;
            }
            &-body {
                clear: both;
            }
            &-searchbox+&-body,
            *+.list-group {
                border-top-width: 1px;
                border-left: none;
                border-right: none;
                border-bottom: none;
            }
        }
        .list-group {
            clear: both;
        }
        *+.list-group {
            border-top-width: 1px;
            border-left: none;
            border-right: none;
            border-bottom: none;
        }
        .list-group {
            .list-group-item {
                &:not(:first-child) {
                    border-top: 2px solid @brand-radialempty !important;
                }
                &:last-child {
                    border-bottom: 2px solid @brand-radialempty !important;
                }
            }
        }
    }
}