.px-2-rem(@px) {
    @rem: unit(unit((@px / @base-font-size)), rem);
}

.min-size-rem(@px) {
    .px-2-rem(@px);
    @min-size-rem: @rem;
}

.max-size-rem(@px) {
    .px-2-rem(@px);
    @max-size-rem: @rem;
}

.min-res-rem(@px) {
    .px-2-rem(@px);
    @min-res-rem: @rem;
}

.max-res-rem(@px) {
    .px-2-rem(@px);
    @max-res-rem: @rem;
}

.diff-unitless(@min, @max) {
    @diff-unitless: (unit(@max) - unit(@min));
}

.size-diff(@min, @max) {
    .diff-unitless(@min, @max);
    @size-diff: @diff-unitless;
}

.res-diff(@min, @max) {
    .diff-unitless(@min, @max);
    @res-diff: @diff-unitless;
}

.scale-prop (@prop, @min-size, @max-size, @min-res, @max-res) {
    .min-size-rem(@min-size);
    .max-size-rem(@max-size);
    .min-res-rem(@min-res);
    .max-res-rem(@max-res);
    .size-diff(@min-size, @max-size);
    .res-diff(@min-res, @max-res);
    @{prop}: @min-size-rem;
    @media screen and (min-width: @min-res) {
        @{prop}: calc(@min-size-rem + @size-diff * ((100vw - @min-res) / @res-diff));
    }
    @media screen and (min-width: @max-res) {
        @{prop}: @max-size-rem;
    }
}