.table-list {
    .table-only {
        display: none;
    }
    .table-list-header {
        display: none;
    }
    .panel {
        &-heading,
        &-footer {
            .btn {
                color: @brand-secondary;
                background-color: transparent;
                &:hover,
                &:active,
                &:focus,
                &:active:hover,
                &:active:focus {
                    color: @brand-primary;
                }
                .icon {
                    .scale-prop(font-size, 30px, 40px, @screen-mobile, @screen-desktop);
                }
                span:not(.icon) {
                    line-height: 1.5rem;
                    .scale-prop(margin-right, 15px, 25px, @screen-mobile, @screen-desktop);
                }
                &:last-child {
                    .scale-prop(margin-right, -30px, -35px, @screen-mobile, @screen-desktop);
                }
            }
        }
        &-footer {
            text-align: right;
            background-color: #fff;
            border-top-color: #fff;
            margin-top: -2px;
        }
    }
}

@media (max-width: @screen-sm-max) {
    .table-list {
        .panel-body {
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: none;
            &.collapse.in{
                overflow: visible;
            }
            &>*:first-child {
                margin-top: 15px;
            }
            &>*:last-child {
                margin-bottom: 15px;
            }
        }
        .panel-heading {
            .caret {
                border: none;
                transform: rotate(180deg);
                width: auto;
                height: auto;
                margin: 0;
                float: right;
                &::before {
                    font-family: @icon-font;
                    content: '\e900';
                }
            }
            &.collapsed {
                .caret {
                    transform: rotate(0deg);
                }
            }
        }
        .panel-heading:not(.collapsed) .panel-body {
            &.collapsing {
                &+.panel-footer {
                    &>* {
                        display: block;
                    }
                }
            }
        }
        .panel-heading.collapsed+.panel-body {
            &.collapse:not(.in),
            &.collapsing {
                &+.panel-footer {
                    &>* {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (min-width: @screen-md) {
    .list-only {
        display: none;
    }
    .table-only {
        display: block;
    }
    .table-list {
        display: table;
        .table-list-header {
            display: table-row;
            &>* {
                padding-bottom: 0;
            }
            [class*="col-"] {
                .scale-prop(font-size, 25px, 30px, @screen-mobile, @screen-desktop);
            }
        }
        .panel {
            display: table-row;
            &-heading,
            &-body,
            &-footer {
                display: table-cell;
                border: none;
                background-color: #fff;
                color: @brand-default;
                vertical-align: middle;
                overflow: visible;            
            }
            &-heading,
            &-footer {
                padding: 0;
                .btn {
                    padding: 0;
                }
            }
            &-body {
                [class*='col-'] {
                    display: inline-block;
                    &:not(:last-child) {
                        padding-right: 30px;
                    }
                }
                .generate-columns(8);
                .form-group {
                    label {
                        display: none;
                    }
                    margin-bottom: 0;
                }
            }
        }
    }
}

.generate-columns(@n, @i: 1) when (@i =< @n) {
    .col-@{i} {
        width: (@i * 100% / @n);
    }
    .generate-columns(@n, (@i + 1));
}